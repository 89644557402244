import config from "@/config";
import { handleResponse, handleErrors } from "./api-helper";
import authHeader from "./auth-header";

class BaseApiService {
  baseUrl = config.apiUrl;
  resource;
  fetching = false;

  constructor(resource) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id = "", appendUrl = "") {
    let url = this.baseUrl;
    let header = authHeader();
    if (header && Object.keys(header).length > 0) {
      url += "/api";
    }
    return `${url}/${this.resource}` + (id ? `/${id}` : ``) + appendUrl;
  }

  serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return "?" + str.join("&");
  };
}

class ReadOnlyApiService extends BaseApiService {
  constructor(resource) {
    super(resource);
  }
  async fetch(query = {}) {
    try {
      config.headers = authHeader();
      let url = this.getUrl();
      if (query) {
        url += this.serialize(query);
      }
      const response = await fetch(url, config);
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
  async get(id, appendUrl, includeCredentials = false) {
    try {
      if (!id) throw Error("Id is not provided");
      let options = {};
      if (Object.keys(authHeader()).length > 0) {
        options.headers = authHeader();
      }
      if (includeCredentials) {
        options.credentials = "include";
      }

      const response = await fetch(this.getUrl(id, appendUrl), options);
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
}

class ModelApiService extends ReadOnlyApiService {
  constructor(resource) {
    super(resource);
  }
  async post(data = {}, appendUrl) {
    try {
      const response = await fetch(this.getUrl("", appendUrl), {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
  async put(id, data = {}, appendUrl = "") {
    if (!id) throw Error("Id is not provided");
    try {
      const response = await fetch(this.getUrl(id, appendUrl), {
        method: "PUT",
        body: JSON.stringify(data),
        headers: authHeader(),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
  async delete(id) {
    if (!id) throw Error("Id is not provided");
    try {
      await fetch(this.getUrl(id), {
        method: "DELETE",
        headers: authHeader(),
      });
      return true;
    } catch (err) {
      handleErrors(err);
    }
  }
}

class UserApiService extends ReadOnlyApiService {
  constructor() {
    super("user");
  }
}

class ArticleApiService extends ModelApiService {
  constructor() {
    super("article");
  }
  async updateMedia(articleId, data = {}) {
    try {
      const response = await fetch(this.getUrl(articleId, "/media"), {
        method: "PUT",
        body: JSON.stringify(data),
        headers: authHeader(),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
  async deleteDrafts() {
    try {
      const response = await fetch(this.getUrl(null, "/delete-drafts"), {
        method: "DELETE",
        headers: authHeader(),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
}

class ArticleCategoryApiService extends ModelApiService {
  constructor() {
    super("article-category");
  }
}
class ArticleStatusApiService extends ReadOnlyApiService {
  constructor() {
    super("article-status");
  }
}
class BusinessApiService extends ReadOnlyApiService {
  constructor() {
    super("business");
  }
}

class LeadApiService extends ModelApiService {
  constructor() {
    super("lead");
  }
  async createUser(leadId, data = {}) {
    try {
      const response = await fetch(this.getUrl(leadId, "/createUser"), {
        method: "POST",
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
}

class IntegrationApiService extends ModelApiService {
  constructor() {
    super("integration");
  }
}
class FeedApiService extends ModelApiService {
  constructor() {
    super("feed");
  }
}
class FeedSourceApiService extends ModelApiService {
  constructor() {
    super("feed-source");
  }
}

class PaymentApiService extends ModelApiService {
  constructor() {
    super("payment");
  }
  async createIntent(sessionId, data = {}) {
    try {
      data[sessionId] = sessionId;
      const response = await fetch(this.getUrl(null, "/create-intent"), {
        method: "POST",
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }
}
class SiteApiService extends ModelApiService {
  constructor() {
    super("site");
  }
}
class AffiliateApiService extends ModelApiService {
  constructor() {
    super("affiliate");
  }
}

export default {
  user: new UserApiService(),
  lead: new LeadApiService(),
  integration: new IntegrationApiService(),
  business: new BusinessApiService(),
  article: new ArticleApiService(),
  articleCategory: new ArticleCategoryApiService(),
  articleStatus: new ArticleStatusApiService(),
  feed: new FeedApiService(),
  feedSource: new FeedSourceApiService(),
  payment: new PaymentApiService(),
  site: new SiteApiService(),
  affiliate: new AffiliateApiService(),
};
